import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import axios from 'axios';
import Cookies from 'universal-cookie';

import {getGlid, getGstData, checkFinaggCriteria, fullLoginIds, callReauth, checkImExpiry, getAK, getEncGlidCookie, setEncrGLID, eventBus, isJourneyLive} from '../../utils';

import MainPage from '../pages/MainPage'
import AboutUsPage from '../pages/AboutUsPage';
import ProductsPage from '../pages/ProductsPage';
import OneInfLenderPage from '../pages/OneInfLenderPage'
import LoanTapLenderPage from '../pages/LoanTapLenderPage';
import IIFLLenderPage from '../pages/IIFLLenderPage';
import PoonawallaLenderPage from '../pages/PoonawallaLenderPage';
import IciciLenderPage from '../pages/IciciLenderPage';
import IndifiLenderPage from '../pages/IndifiLenderPage';
//import CreditEnableLenderPage from '../pages/CreditEnableLenderPage';
import ApplicationsPage from '../pages/ApplicationsPage';
import TataCapitalPage from '../pages/TataCapitalPage';
import CRMComponent from '../crm/AuthComponent';
import NewHomePage from '../pages/NewHomePage';
import TestComp from "../pages/TestPage"

const cookie = new Cookies();
class RouterComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            flag: false,
            visited: sessionStorage.getItem('visited') ? true : false,
            newForm: false,
        };

        if (!sessionStorage.getItem('visited')) {
            sessionStorage.setItem('visited', 'true');
        }

        var glid = getGlid();
        window.glid = glid;

        // if (glid) {
        //     this.userCurrentStatus(glid);
        // }
        this.updateNewFormStateRouter = this.updateNewFormStateRouter.bind(this);
        this.handleDoneLogin = this.handleDoneLogin.bind(this);
    }

    updateNewFormStateRouter(newValue) {
        this.setState({ newForm: newValue });
    }

    userCurrentStatus(glid) {

        var host = window.location.hostname;
        var statusUrl = host == 'loans.indiamart.com' ? 'https://loans.indiamart.com/api/v1/checkCurrentStatus' : 'https://dev-loans.indiamart.com/api/v1/checkCurrentStatus';

        var statusData = {
            "glid": glid,
        };

        axios({
            method: 'post',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            url: statusUrl,
            data: JSON.stringify(statusData),
            timeout: 10000
        }).then((response) => {
            if (response['data'] == true) {
                this.setState({ flag: true });
            }
        }).catch(error => {
            console.error("Error occurred in callin checkCurrentStatus: ", error);
        })
    
}

async appendData(glid){
    var host = window.location.hostname;
    const queryParamString = window.location.search.substring(1);
    //console.log("Query param string => ", queryParamString);
    let ak = getAK();
    var url = host == "loans.indiamart.com"? "https://loans.indiamart.com/api/v1/getEncValue":"https://dev-loans.indiamart.com/api/v1/getEncValue";

    const formData = new FormData();
    formData.append('glid', glid);
    formData.append('ak', ak);
  
    try {
      const response = await axios.post(url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      if(response.data.CODE == '200'){
        //console.log("Output from encVal => ", response.data.OUTPUT)
        var finalUrl = host=="loans.indiamart.com"?"https://www.hellotrade.com"+"?encVal="+response.data.OUTPUT+"&userSourceData=loans,"+queryParamString :"http://localhost:3000/"+"?encVal="+response.data.OUTPUT+"&userSourceData=loans,"+queryParamString;
        window.location.href = finalUrl;
      } else if(response.data.CODE == '400'){
        console.log("not redirecting as gst not present")
      } else{
        console.error("Error:", response.data)
      }
    } catch (error) {
      console.error('Error:', error);
    }
}

 handleDoneLogin(data){
    //console.log("Received:", data.message);
    if(data.message == "true"){
        //console.log("In handle done login , data => ", data);
        var glid = getGlid();
        // if(isJourneyLive()){
            console.log("redirect these to helloTrade");
            this.appendData(glid);
        // }
    }
};


async componentDidMount() {
    var glid = getGlid();
    // Subscribe to the event
    eventBus.subscribe("doneLogin", this.handleDoneLogin);
        
    // if(glid =='192395864' || glid == '132160368'){
    // console.log("Setting hellotrade true => ", glid);
    // this.appendData(glid);
    // }

   
        let imStatus = await checkImExpiry();
        if(imStatus == "expired"){
            callReauth();
        } else if(imStatus == "notExpired"){
            eventBus.emit("doneLogin", { message: "true" });
        }

    var AK = getAK();   

    if(glid && AK && getEncGlidCookie() == ''){
        setEncrGLID(glid,AK)
    }

    // else if(glid == "13035342" || glid == '155069684' || ((glid.endsWith('1') || glid.endsWith('2') || glid.endsWith('3') || glid.endsWith('4') || glid.endsWith('5')) && !checkFinaggCriteria())){
    //             this.setState({newForm:true})
    // } else{
    //     this.setState({newForm:false})
    // }
}

componentWillUnmount(){
    eventBus.unsubscribe("doneLogin", this.handleDoneLogin);
}


    render() {
    //  cookie.set("ImeshVisitor","fn=J***|ln=|em=s*************@gmail.com|phcc=91|iso=IN|mb1=8299162594|pct=0|ctid=70756|glid=693715|cd=11/JUN/2024|cmid=|utyp=F|ev=V|uv=V|usts=|admln=|admsales=|pwl=0")
        
        return (
            <>
                <Router>
                    <div>
                        <Routes>
                        {/* <Route path="/" element={this.state.newForm ? <NewHomePage/> : !this.state.visited && this.state.flag ? <Navigate to="/applications" /> : <MainPage newForm={this.state.newForm} updateNewFormStateRouter={this.updateNewFormStateRouter}/>} /> */}
                            <Route path="/" element={<NewHomePage />} />
                            {/* <Route path="/applications" element={<ApplicationsPage />} /> */}
                            <Route path="/about" element={<AboutUsPage />} />
                            <Route path="/product" element={<ProductsPage />} />
                            <Route path="/partner/oneinfinity" element={<OneInfLenderPage />} />
                            {/* <Route path="/partner/loantap" element={<LoanTapLenderPage />} /> */}
                            <Route path="/partner/iifl" element={<IIFLLenderPage />} />
                            <Route path="/partner/icici" element={<IciciLenderPage />} />
                            {/* <Route path="/partner/poonawalla" element={<PoonawallaLenderPage />} /> */}
                            <Route path="/partner/indifi" element={<IndifiLenderPage />} />
                            {/* <Route path="/partner/creditenable" element={<CreditEnableLenderPage/>}/> */}
                            <Route path="/partner/tatacapital" element={<TataCapitalPage />} />
                            <Route path="/crm" element={<CRMComponent />} />
                            {/* <Route path="/test" element={<TestComp />} /> */}
                        </Routes>
                    </div>
                </Router>
            </>
        );
    }

}

export default RouterComponent;