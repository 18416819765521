import axios from "axios";

export async function getConsolidatedData(days, service) {
    const host = window.location.hostname;
    const getConsDataURL = host === 'loans.indiamart.com' 
        ? "https://loans.indiamart.com/api/v1/getConsData"
        : "https://dev-loans.indiamart.com/api/v1/getConsData";

    const formData = new URLSearchParams();
    formData.append("days", days);
    formData.append("service", service);

    try {
        const { data } = await axios.post(getConsDataURL, formData, {
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            timeout: 10000
        });

        console.log("Bureau data_obj =>", data);
        return data.data;
    } catch (error) {
        console.error("An error occurred in utils gstapi call:", error);
        return "";
    }
}

export function handleDownloadClick(url){
    if(url !== ""){
        var host = window.location.hostname;

        if(host == 'loans.indiamart.com'){
            var apiURL = "https://loans.indiamart.com/api/v1/viewDoc";
        }
        else {
            var apiURL = "https://dev-loans.indiamart.com/api/v1/viewDoc";
        }

        var inData = {
            "url":url
        };

        axios({
            method: 'post',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            url: apiURL,
            data: inData,
            timeout:2000
        }).then(function (response) {
            if(response.data.CODE === "200"){
                window.open(response.data.PDF_URL,'_blank');
            }
        }).catch(function (error) {
            console.log(error);
        });
    }
};
